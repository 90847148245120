import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Toast } from 'antd-mobile';
import { category_icon } from '../constants/category/homeCateLink';
import { HomeCateLinkContainer } from './HomeCateLinkStyle';
import Router from 'next/router';

@inject('uiStore', 'configStore')
@observer
export default class HomeCateLink extends Component {
  handleRouter = item => {
    if (item.value === 'tuantuan') {
      if (typeof window !== 'undefined') {
        window.location.href=item.link
      }
    } else if (item.value === 'education') {
      if (this.props.uiStore.loading) return;
      Router.push(`/aixue`);
    } else if (!item.link.includes('generalHomePage')) {
      if (this.props.uiStore.loading) return;
      Router.push(`${item.link}`);
    } else {
      if (this.props.uiStore.loading) return;
      Router.push(
        `/generalHomePage/[category]`,
        `/generalHomePage/${item.category}`
      );
    }
  };
  render() {
    return (
      <HomeCateLinkContainer>
        <div className="HomeCateLink">
          {category_icon.map(item => {
            return (
              <div
                className="secondary-category"
                key={item.value}
                onClick={() => this.handleRouter(item)}
              >
                <img src={item.icon} alt={item.value} />
                <span>{item.title}</span>
              </div>
            );
          })}
        </div>
      </HomeCateLinkContainer>
    );
  }
}
