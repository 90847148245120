import React, { Component } from 'react';
import HomeCateLink from '../presentational/HomeCateLink';
import { Modal, Toast } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import GroupCardList from '../container/Group/GroupCardList';
import CardMenu from '../home/CardMenu';
import HeaderMobileContainer from '../home/HeaderMobileContainer';
import styled from 'styled-components';
import SectionHeader from '../common/SectionHeader';
import FeedList from '../components/FeedList';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import LoadingIndicator from '../category/common/LoadingIndicator';
import mitt, { EVENT } from '../common/SMEvent';

export const sortConfig = [
  { label: '智能排序', value: '' },
  { label: '最新发布', value: 'latest' }
];

const CategoryBanners = dynamic(() => import('../category/common/CategoryBanners'), {
  ssr: false,
  loading: () => <LoadingIndicator />
});


@inject('uiStore')
@inject('homePageFeedStore')
@inject('configStore', 'searchListingStore', 'groupStore')
@observer
class HomePage extends Component {
  static async getInitialProps(props) {
    if (typeof window !== 'undefined') {
      Toast.loading('加载中');
    }
    // await props.rootStore.groupStore.getCurrentGroups();
    if (typeof window !== 'undefined') {
      Toast.hide();
    }
  }

  async componentDidMount() {
    const { searchListingStore } = this.props;
    // topicStore.clear();
    if (searchListingStore.scrollTopOffset > 0) {
      const scrollY = searchListingStore.scrollTopOffset;
      setTimeout(() => {
        scrollTo(0, scrollY);
      });
    }
    window.addEventListener('scroll', this.scrollHandler);

    mitt.emit(EVENT.SET_SHARE_CONTENT, {
      title: '找什么，神马都有！',
      url:'https://m.shenmanz.com/',
      content: '神马网，新西兰中文社区。21万本地用户，每天数万浏览。上神马发帖，让更多人看到',
      image: null
    });
  }

  scrollHandler = () => {
    const { searchListingStore } = this.props;
    const offset =
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop;
    searchListingStore.setScroll(offset);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <Styled>
        <Head>
          <title>神马网，新西兰中文集市</title>
        </Head>
        <div className="home_container">
          <HeaderMobileContainer />
          <HomeCateLink />
          <CategoryBanners category="home" />
          <CardMenu />
          {/*<GroupCardList*/}
          {/*  title="热门小组"*/}
          {/*  categoryIndex={0}*/}
          {/*  initData={this.props.groupStore.currentGroups}*/}
          {/*/>*/}
          <SectionHeader contentText={'随便看看'} />
          {/*<WhiteSpace size="xs" />*/}
          <FeedList feedStore={this.props.homePageFeedStore} />
          <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
            <a style={{ color: 'grey' }} href="https://beian.miit.gov.cn">
              沪ICP备17027179号-2
            </a>
          </div>
        </div>
      </Styled>
    );
  }
}

const Styled = styled.div`
  @media (min-width: ${props => props.theme.desktopMinWidth}) {
    width: ${props => props.theme.maxWidth};
    margin: auto;
  }
  overflow-x: hidden;
  .home_container {
    .home-page-banner {
      max-width: 414px;
      height: 85px;
      background: url('https://res.cloudinary.com/shenmanz/image/upload/v1549953566/shenma/banner/chineseNewYearBanner.png')
        no-repeat;
      background-size: cover;
    }
  }

  .float-menu {
    position: fixed;
    right: 15px;
    bottom: 100px;
    z-index: 2;
    text-align: center;
    .menu-item {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.77);
      display: inline-block;
      span {
        color: white;
        font-size: 40px;
      }
    }
    .title {
      margin-top: 5px;
      text-align: center;
      color: rgba(0, 0, 0, 0.77);
      font-weight: bolder;
    }
  }
`;

export default HomePage;
